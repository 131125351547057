export enum EHideOnType {
    UNDEFINED = '',
    Mobile = 'mobile',
    Desktop = 'desktop',
}

export enum EAlignText {
    UNDEFINED = '',
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum EFontWeight {
    UNDEFINED = '',
    THIN = 'thin',
    LIGHT = 'light',
    NORMAL = 'normal',
    MEDIUM = 'medium',
    SEMIBOLD = 'semibold',
    BOLD = 'bold',
    EXTRABOLD = 'extrabold',
    BLACK = 'black',
}

export enum EFontStyle {
    UNDEFINED = '',
    FONT_STYLE_ITALIC = 'italic',
    FONT_STYLE_OBLIQUE = 'oblique',
}

export enum EFontColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    GRAY = 'gray',
    PINK = 'pink',
    YELLOW = 'yellow',
    BLACK = 'black',
    WHITE = 'white',
    SLATE = 'slate',
    ZINC = 'zinc',
    NEUTRAL = 'neutral',
    STONE = 'stone',
    RED = 'red',
    ORANGE = 'orange',
    AMBER = 'amber',
    LIME = 'lime',
    GREEN = 'green',
    EMERALD = 'emerald',
    TEAL = 'teal',
    CYAN = 'cyan',
    SKY = 'sky',
    BLUE = 'blue',
    INDIGO = 'indigo',
    VIOLET = 'violet',
    PURPLE = 'purple',
    FUCHSIA = 'fuchsia',
    ROSE = 'rose',
    CUSTOM = 'custom',
}

export enum EHeadingTypes {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
}

export enum ETextDecorations {
    UNDEFINED = '',
    UNDERLINE = 'underline',
    LINE_THROUGH = 'line-through',
}

export enum EUnderlineTypes {
    UNDEFINED = '',
    DIVIDER = 'divider',
    BORDER = 'border',
}

export enum EImagePositions {
    UNDEFINED = '',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum EComponentDirections {
    ROW = 'row',
    COLUMN = 'column',
}
export enum EMobileBehaviors {
    COLUMN = 'column',
    CAROUSEL = 'carousel',
}
export enum EVerticalAlignments {
    START = 'start',
    END = 'end',
    CENTER = 'center',
}
